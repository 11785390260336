import * as React from "react";

import {ContactSection} from "./ContactSection";
import {HeroSection} from "./HeroSection";
import {PartnerCollaborationSection} from "./PartnerCollaborationSection";
import {QualitySection} from "./QualitySection";
import {RegisterChecklist} from "./RegisterChecklist";
import {SubscriptionOptions} from "./SubscriptionOptions";

export const ForAgentDynamic = () => {
    return (
        <>
            <HeroSection />
            <SubscriptionOptions />
            <QualitySection showRegistrationButton />
            <RegisterChecklist />
            <PartnerCollaborationSection />
            <ContactSection />
        </>
    );
};
