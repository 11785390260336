import {pluralize} from "@web2/string_utils";

// Gallery Pictures
import picture1 from "../../styles/assets/images/aboutUsGallery/1.jpg";
import picture2 from "../../styles/assets/images/aboutUsGallery/2.jpg";
import picture3 from "../../styles/assets/images/aboutUsGallery/3.jpg";
import picture4 from "../../styles/assets/images/aboutUsGallery/4.jpg";
import picture5 from "../../styles/assets/images/aboutUsGallery/5.jpg";
import picture6 from "../../styles/assets/images/aboutUsGallery/6.jpg";
import picture7 from "../../styles/assets/images/aboutUsGallery/7.jpg";
import picture8 from "../../styles/assets/images/aboutUsGallery/8.jpg";
import picture9 from "../../styles/assets/images/aboutUsGallery/9.jpg";
import picture10 from "../../styles/assets/images/aboutUsGallery/10.jpg";
import picture11 from "../../styles/assets/images/aboutUsGallery/11.jpg";
import icon4 from "../../styles/assets/images/aboutUsGallery/statsIcons/calendar.png";
// Stats Icons
import icon1 from "../../styles/assets/images/aboutUsGallery/statsIcons/cooperation.png";
import icon2 from "../../styles/assets/images/aboutUsGallery/statsIcons/hand.png";
import icon3 from "../../styles/assets/images/aboutUsGallery/statsIcons/offer.png";
import icon5 from "../../styles/assets/images/aboutUsGallery/statsIcons/offer_copy.png";
import icon6 from "../../styles/assets/images/aboutUsGallery/statsIcons/offer_copy_2.png";
// Gallery Thumbnails
import thumb1 from "../../styles/assets/images/aboutUsGallery/thumbs/1_t.jpg";
import thumb2 from "../../styles/assets/images/aboutUsGallery/thumbs/2_t.jpg";
import thumb3 from "../../styles/assets/images/aboutUsGallery/thumbs/3_t.jpg";
import thumb4 from "../../styles/assets/images/aboutUsGallery/thumbs/4_t.jpg";
import thumb5 from "../../styles/assets/images/aboutUsGallery/thumbs/5_t.jpg";
import thumb6 from "../../styles/assets/images/aboutUsGallery/thumbs/6_t.jpg";
import thumb7 from "../../styles/assets/images/aboutUsGallery/thumbs/7_t.jpg";
import thumb8 from "../../styles/assets/images/aboutUsGallery/thumbs/8_t.jpg";
import thumb9 from "../../styles/assets/images/aboutUsGallery/thumbs/9_t.jpg";
import thumb10 from "../../styles/assets/images/aboutUsGallery/thumbs/10_t.jpg";
import thumb11 from "../../styles/assets/images/aboutUsGallery/thumbs/11_t.jpg";
import wielgo from "../../styles/assets/images/employees/m_wielgo.jpg";
import michalski from "../../styles/assets/images/employees/michalski.png";
import szulkowska from "../../styles/assets/images/employees/szulkowska.png";
import zysko from "../../styles/assets/images/employees/zysko.png";
import logo2 from "../../styles/assets/images/partnerLogo/bi.svg";
import logo3 from "../../styles/assets/images/partnerLogo/mam_startup.svg";
import logo4 from "../../styles/assets/images/partnerLogo/mkt_przy_kawie_logo.png";
import logo1 from "../../styles/assets/images/partnerLogo/nm.png";
import logo6 from "../../styles/assets/images/partnerLogo/socialpress_logo.png";
import logo5 from "../../styles/assets/images/partnerLogo/wm_logo.png";

export const aboutUsBullets = [
    {text: "Prezentujemy idealnie dopasowane oferty"},
    {text: "Nieruchomości zawsze wskazujemy na mapie"},
    {text: "Projekt realizuje doświadczony 100-osobowy zespół portalu rynekpierwotny.pl"}
];

export const aboutUsBoxes = [
    {
        text: "„Twórcy serwisu pracują nad rozwiniętym systemem weryfikującym oferty, który oparty jest na uczeniu maszynowym (ang. machine learning)”",
        image: logo1,
        alt: "NowyMarketing",
        link: "https://nowymarketing.pl/a/24016,wystartowal-gethome-pl-innowacyjna-alternatywa-na-rynku-wtornym-nieruchomosci"
    },
    {
        text: "„gethome.pl to nowy portal nieruchomości, na którym wreszcie wygodnie znajdziesz swoje wymarzone M”",
        image: logo2,
        alt: "Business Insider",
        link: "https://businessinsider.com.pl/firmy/szukasz-mieszkania-na-rynku-wtornym-wreszcie-znajdziesz/6d954nf"
    },
    {
        text: "„Portal błyskawicznie zdobywa partnerów biznesowych i użytkowników”",
        image: logo3,
        alt: "MamStartup",
        link: "https://mamstartup.pl/wystarczyly-3-miesiace-gethomepl-zmienia-branze-nieruchomosci"
    },
    {
        text: "„gethome.pl ponownie zaskakuje nowymi standardami w branży ogłoszeniowej. Tym razem wprowadza dla swoich partnerów biznesowych wirtualne numery.”",
        image: logo4,
        alt: "MarketingPrzyKawie",
        link: "https://marketingprzykawie.pl/espresso/portal-nieruchomosci-gethome-wprowadza-numery-wirtualne-dla-klientow/"
    },
    {
        text: "„Portal od startu bardzo szybko zdobywa w naturalny sposób nowych użytkowników - i to bez większych nakładów finansowych na marketing. Dla niektórych zapytań w Google trafił już na najwyższe pozycje wyszukiwania.”",
        image: logo5,
        alt: "WirtualneMedia",
        link: "https://www.wirtualnemedia.pl/artykul/sztuczna-inteligencja-znajdzie-ci-klientow"
    },
    {
        text: "„gethome.pl rośnie błyskawicznie – w samym tylko pierwszym kwartale tego roku portal odwiedziło ponad 500 tys. osób poszukujących nieruchomości – o 80% więcej w porównaniu do poprzedniego kwartału.”",
        image: logo6,
        alt: "SocialPress",
        link: "https://socialpress.pl/2021/04/jak-w-prosty-sposob-znalezc-mieszkanie-przez-internet"
    }
];

/**
 * Keep in mind that the counter calculations you find below are a temporary solution, since no such information is available to fetch yet.
 * `startDate` was set to December 2019 as a gethome.pl launch day, and `startDateStatisticsCounter` is set to week when changes will be released to production.
 */
//
// const startDate = "08/01/2019";
// const currentDate = moment().toDate();
// const startDateStatisticsCounter = "02/14/2020";

// const weeksSinceCountedStarted = moment(new Date(currentDate)).diff(new Date(startDateStatisticsCounter), "weeks", true);
// const monthsSinceCountedStarted = moment(new Date(currentDate)).diff(new Date(startDateStatisticsCounter), "months", true);

// const agencyCounter = 407 + Math.floor(weeksSinceCountedStarted) * 10; // starting point 407, increase by 10 every week
// const agencyCounter = 1098; // Hardcoded by request.
// const monthSinceStart = Math.ceil(moment(new Date(currentDate)).diff(new Date(startDate), "months", true));
const yearsSinceStart = 3;
// const visitorsMonthly = 30000 + Math.floor(monthsSinceCountedStarted) * 5000; // starting point 30K, increase every month by 5K
const visitorsMonthly = 600000; // Hardcoded by request

const numberWithSpaces = (value: number) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
const yearsPlural = pluralize(["rok minął", "lata minęły", "lat minęło"]);

export const aboutUsStats = [
    {
        text: "najlepszych w Polsce biur nieruchomości już z nami współpracuje",
        number: "1 456",
        image: icon1,
        height: 139,
        width: 139
    },
    {
        text: "ofert nieruchomości na sprzedaż prezentujemy",
        number: "91 621",
        image: icon2,
        height: 155,
        width: 139
    },
    {
        text: "ofert nieruchomości na wynajem jest na naszym portalu",
        number: "15 673",
        image: icon3,
        height: 151,
        width: 139
    },
    {
        text: `${yearsPlural(yearsSinceStart)} od startu projektu gethome`,
        number: yearsSinceStart,
        image: icon4,
        height: 121,
        width: 124
    },
    {
        text: "specjalistów pracuje nad rozwojem portalu",
        number: "100",
        image: icon5,
        height: 142,
        width: 135
    },
    {
        text: "osób odwiedza nas każdego miesiąca",
        number: numberWithSpaces(visitorsMonthly),
        image: icon6,
        height: 124,
        width: 156
    }
];

export const team = [
    {
        name: "Jerzy Michalski",
        source: michalski,
        email: "j.michalski@gethome.pl",
        title: "Członek zarządu Property Group"
    },
    {
        name: "Katarzyna Zyśko",
        source: zysko,
        email: "k.zysko@gethome.pl",
        title: "New Business Specialist"
    },
    {
        name: "Aleksandra Szulkowska",
        source: szulkowska,
        email: "a.szulkowska@gethome.pl",
        title: "Koordynator ds. Wsparcia Sprzedaży"
    },
    {
        name: "Marek Wielgo",
        source: wielgo,
        email: "m.wielgo@gethome.pl",
        title: "Real Estate Market Expert"
    }
];

export interface IAboutUsGallery {
    source: string;
    alt: string;
}

export const thumbGallery: IAboutUsGallery[] = [
    {
        source: thumb1,
        alt: "thumb1"
    },
    {
        source: thumb2,
        alt: "thumb2"
    },
    {
        source: thumb3,
        alt: "thumb3"
    },
    {
        source: thumb4,
        alt: "thumb4"
    },
    {
        source: thumb5,
        alt: "thumb5"
    },
    {
        source: thumb6,
        alt: "thumb6"
    },
    {
        source: thumb7,
        alt: "thumb7"
    },
    {
        source: thumb8,
        alt: "thumb8"
    },
    {
        source: thumb9,
        alt: "thumb9"
    },
    {
        source: thumb10,
        alt: "thumb10"
    },
    {
        source: thumb11,
        alt: "thumb11"
    }
];

export const AboutUsModalGallery: IAboutUsGallery[] = [
    {
        source: picture1,
        alt: "picture1"
    },
    {
        source: picture2,
        alt: "picture2"
    },
    {
        source: picture3,
        alt: "picture3"
    },
    {
        source: picture4,
        alt: "picture4"
    },
    {
        source: picture5,
        alt: "picture5"
    },
    {
        source: picture6,
        alt: "picture6"
    },
    {
        source: picture7,
        alt: "picture7"
    },
    {
        source: picture8,
        alt: "picture8"
    },
    {
        source: picture9,
        alt: "picture9"
    },
    {
        source: picture10,
        alt: "picture10"
    },
    {
        source: picture11,
        alt: "picture11"
    }
];
