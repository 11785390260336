import {escape} from "lodash";
import {Dispatch} from "redux";

import {currentStaticsDir} from "../../app/constants/current_statics_dir";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {IStandardMetaData} from "../reducers/meta_data_reducer";
import {setClientMetaData} from "./set_client_meta_data";

export const UPDATE_META_DATA = "metaData/UPDATE_META_DATA";
export const UPDATE_META_TITLE_DESCRIPTION = "metaData/UPDATE_META_TITLE_DESCRIPTION";

export interface IUpdateMetaDataAction extends IStandardMetaData {
    type: typeof UPDATE_META_DATA;
}

export interface IUpdateMetaTitleDescriptionAction extends Pick<IStandardMetaData, "title" | "description"> {
    type: typeof UPDATE_META_TITLE_DESCRIPTION;
}

export interface IMetaDataOptions {
    keywords?: string;
    canonical?: string;
    author?: string;
    robots?: string;
    image?: string;
    url?: string;
    relNext?: string;
    relPrev?: string;
    hideSuffix?: boolean;
    lang?: string;
    maxImagePreview?: "none" | "standard" | "large";
}

const defaultImage = `${ghCommonUrl}/${currentStaticsDir}/gethome-og-logo.png`;

export const updateMetaData =
    (title: string, description: string, route: {pathname: string; query: Record<string, string> | null}, options: IMetaDataOptions) =>
    async (dispatch: Dispatch, getState: () => IStore) => {
        // we are also asynchronously fetching custom metadata, be aware that a race condition is possible. Always check if custom meta should be applied on current path
        const isQueryEmpty = route.query ? Object.keys(route.query).length === 0 : true;
        const customMetaData = getState().metaData.customMetaData;
        const customMetaToApply = isQueryEmpty && customMetaData?.appliesTo === route.pathname ? customMetaData : null;

        const {canonical, author, image = defaultImage, url, relNext, relPrev, hideSuffix, robots, keywords, lang, maxImagePreview} = options;
        const escapeTitle = escape(customMetaToApply?.title || title);
        const escapeAuthor = escape(author);
        const escapeDescription = escape(customMetaToApply?.description || description);
        const escapeCanonical = escape(canonical);
        const escapePrev = relPrev && escape(relPrev);
        const escapeNext = relNext && escape(relNext);
        const metaData: IStandardMetaData = {
            title: escapeTitle,
            description: escapeDescription,
            relPrev: escapePrev,
            relNext: escapeNext,
            robots,
            keywords: keywords,
            author: escapeAuthor,
            canonical: escapeCanonical,
            image,
            url,
            hideSuffix,
            lang,
            maxImagePreview
        };

        // update DOM
        setClientMetaData(metaData);

        return dispatch({type: UPDATE_META_DATA, ...metaData});
    };

export const updateMetaTitleDescription = (title: string, description: string) => async (dispatch: Dispatch, getState: () => IStore) => {
    const escapeTitle = escape(title);
    const escapeDescription = escape(description);

    const metaData: IStandardMetaData = {
        title: escapeTitle,
        description: escapeDescription
    };

    setClientMetaData(metaData);

    return dispatch({type: UPDATE_META_TITLE_DESCRIPTION, ...metaData});
};
