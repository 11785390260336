import * as React from "react";
import {css, Theme} from "@emotion/react";
import {appPath} from "@web2/gh_routes";
import {ArrowNextIcon, CheckIcon} from "@web2/icons";

import {StyledAgencyButton} from "../../app/atoms/Buttons";
import {GridStatic} from "../../app/atoms/Layout";
import {Paragraph} from "../../app/atoms/Paragraph";
import {handleRegisterAgencyBtn} from "../../tracking/google_tag_manager/registerAgencyBtnEvents";

import blueWave from "../../styles/assets/background/blue_wave_bg.jpg";
import logo from "../../styles/assets/logo/gethome.svg";

const bullets = [
    {text: "Dostarczymy Ci wysokiej jakości leady sprzedażowe"},
    {text: "Wiemy, że w pracy agenta telefon jest narzędziem podstawowym, dlatego opracowaliśmy unikalne rozwiązania wspierające skuteczność sprzedaży"},
    {text: "Z nami masz pełną kontrolę wydatków na marketing - nie przekroczymy wspólnie ustalonej kwoty"},
    {text: "Możesz eksportować oferty w 5 minut za pomocą ASARI, EstiCRM, Galactica Virgo, Mediarent oraz IMO"}
];

export const RegisterChecklist = () => {
    return (
        <section css={registerChecklistWrapper}>
            <GridStatic width="1230px" css={contentWrapper}>
                <div css={textHolder}>
                    <h2 css={sectionHeader}>
                        Zapraszamy
                        <br />
                        do współpracy!
                    </h2>

                    <Paragraph fontSize={16} lineHeight="2.5rem" color="#fff" marginBottom={1.6}>
                        Jako <b>gethome.pl</b> i <b>rynekpierwotny.pl</b> rocznie dostarczamy naszym partnerom biznesowym ponad 650 000 zgłoszeń od osób realnie
                        poszukujących nieruchomości.
                    </Paragraph>

                    <StyledAgencyButton href={appPath.agencyRegister} onClick={handleRegisterAgencyBtn} variant="success" size="lg" css={styledAgencyButton}>
                        Zarejestruj biuro nieruchomości
                        <ArrowNextIcon mainColor="#fff" />
                    </StyledAgencyButton>
                </div>

                <div css={registerChecklistContainer}>
                    <div css={ghLogo} />
                    <p>oznacza dla Ciebie:</p>
                    <div css={divider} />

                    {bullets.map((item, idx) => (
                        <div css={bulletItem} key={idx}>
                            <CheckIcon />
                            <div>{item.text}</div>
                        </div>
                    ))}
                </div>
            </GridStatic>
        </section>
    );
};

export const sectionHeaderBase = (theme: Theme) => css`
    color: ${theme.colors.gray_dark};
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 4.4rem;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        line-height: 4.4rem;
        font-size: 3.6rem;
    }

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 4rem;
        line-height: 5.6rem;
    }
`;

const registerChecklistWrapper = css`
    position: relative;
    display: flex;
    justify-content: flex-start;
    background-image: url("${blueWave}");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    padding: 4rem 0.5rem;
    height: auto;
`;

const contentWrapper = (theme: Theme) => css`
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        justify-content: space-between;
        flex-direction: row;
    }
`;

const textHolder = (theme: Theme) => css`
    color: #fff;
    text-align: center;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        max-width: 50%;
        padding-right: 6rem;
        text-align: unset;
    }
`;

const sectionHeader = (theme: Theme) => css`
    ${sectionHeaderBase(theme)};
    color: #fff;
    margin-bottom: 3rem;
    margin-top: 0;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        max-width: 500px;
        text-align: left;
    }
`;
/*
Checklist
 */
const registerChecklistContainer = (theme: Theme) => css`
    display: block;
    border: 1px solid #e7e9ed;
    border-radius: 1rem;
    background-color: #ffffff;
    box-shadow: 0 1.6rem 3rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem 2.5rem 2rem;

    p {
        text-align: center;
        font-size: 1.8rem;
        margin: 1rem 0 2rem;
        color: ${theme.colors.gray_dark};
    }
`;

const ghLogo = css`
    height: 50px;
    background: url("${logo}") no-repeat center;
`;

const divider = css`
    box-sizing: border-box;
    height: 2px;
    width: 100%;
    border: 1px solid #e1e8f0;
    margin-bottom: 1.5rem;
`;

const bulletItem = (theme: Theme) => css`
    position: relative;
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: ${theme.colors.text_color};
    margin-bottom: 1.5rem;
    display: flex;

    .svg-icon {
        flex-grow: 0;
        flex-shrink: 0;
        fill: ${theme.colors.dark_blue};
        margin-right: 1.5rem;
    }
`;

const styledAgencyButton = (theme: Theme) => css`
    margin: 3rem auto;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        margin: 3rem 0 0 0;
    }
`;
