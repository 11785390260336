import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {darken} from "polished";
import {appPath} from "@web2/gh_routes";

import {ButtonGreen} from "../../app/atoms/Buttons";
import {GridStatic} from "../../app/atoms/Layout";
import {handleRegisterAgencyBtn} from "../../tracking/google_tag_manager/registerAgencyBtnEvents";
import {HeroSectionContactElement} from "./HeroSectionContactElement";

import coverImg from "../../styles/assets/background/agency_lp_cover_light.png";
import logo from "../../styles/assets/logo/gethome.svg";

export const HeroSection = () => {
    return (
        <section css={heroWrapper}>
            <GridStatic width="1230px" css={contentWrapper}>
                <h1 css={subHeading}>Oto jest alternatywa! </h1>
                <h3 css={subHeading}>Gdy inni podnoszą ceny, my podnosimy skuteczność!</h3>
                <h3 css={[subHeading, bold]}>W GetHome dostajesz więcej, a&nbsp;do czerwca nic nie płacisz!</h3>

                <RegisterButton href={appPath.agencyRegister} onClick={handleRegisterAgencyBtn}>
                    Opublikuj za 0zł
                </RegisterButton>

                <div css={headingWrapper}>
                    <div css={ghLogo} />
                    <span>oznacza</span>
                    <h1>rozliczenie za efekt</h1>
                </div>
            </GridStatic>
            <HeroSectionContactElement />
        </section>
    );
};

/*
HeroSectionStyles
 */

const heroWrapper = (theme: Theme) => css`
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding: 4rem 0.5rem 8rem;
    max-height: 540px;
    height: auto;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        background-position: bottom;
        padding: 9rem 0.5rem 6rem;

        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("${coverImg}");
    }
`;

const contentWrapper = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        font-weight: bold;
        font-size: 3.5rem;
        color: ${theme.colors.brand_primary};
        margin: 4rem 0 5rem;
        line-height: 2.7rem;
        position: relative;
    }

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        h1 {
            font-size: 4rem;
            color: ${theme.colors.brand_primary};
            margin: 0 0 1rem;
            line-height: 4rem;

            &:after {
                height: 3px;
                bottom: -10px;
            }
        }
    }
`;

const ghLogo = (theme: Theme) => css`
    width: 140px;
    height: 32px;
    margin-right: 1rem;
    background: url("${logo}") no-repeat center;
    margin-bottom: 0.5rem;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        width: 263px;
        height: 60px;
        margin-bottom: unset;
        margin-right: 2rem;
    }
`;

const headingWrapper = (theme: Theme) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    h1 {
        font-size: 1.6rem;
        color: ${theme.colors.brand_primary};
        margin: 0;
        line-height: 2.7rem;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -3px;
            height: 2px;
            width: 100%;
            background: #45bc83;
            z-index: 2;
            transition: width 0.3s -0.1s;
        }
    }

    span {
        font-size: 1.7rem;
        color: ${theme.colors.text_color};
        line-height: 2.7rem;
        font-weight: 500;
        margin-right: 0.5rem;
    }

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        h1 {
            font-size: 4rem;
            color: ${theme.colors.brand_primary};
            margin: 0;
            line-height: 4rem;

            &:after {
                height: 3px;
                bottom: -10px;
            }
        }

        span {
            font-size: 4rem;
            color: ${theme.colors.text_color};
            line-height: 4rem;
            font-weight: 500;
            margin-right: 1rem;
        }
    }
`;

const subHeading = (theme: Theme) => css`
    font-size: 2rem;
    color: ${theme.colors.text_color};
    text-align: center;
    font-weight: 400;
    margin: 0 0 4rem;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        font-size: 2.5rem;
    }
`;

const bold = css`
    font-weight: 600;
`;

const RegisterButton = styled(ButtonGreen)`
    font-weight: 600;
    margin: 0 0 5rem;
    font-size: 1.4rem;
    box-shadow: 0 0 1.5rem 0.3rem rgba(200, 209, 219, 0.83);
    background-color: ${(props) => props.theme.colors.green_darker};

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        background-color: ${(props) => darken(0.1, props.theme.colors.green_darker)};
        border-color: ${(props) => darken(0.1, props.theme.colors.green_darker)};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        font-size: 2.4rem;
        padding: 1.5rem 6rem;
        margin: 1rem 0 5rem;
    }
`.withComponent("a");
