import * as React from "react";
import {css, Theme} from "@emotion/react";
import {darken} from "polished";
import {Button} from "@web2/button";
import {appLink} from "@web2/gh_routes";

import {getThemeVariable} from "../../styles/linaria_variable_factory";
import {FOR_AGENT_SECTION_WIDTH} from "../constants";

const options = [
    {
        count: "20",
        range: "1-20",
        price: "349 zł"
    },
    {
        count: "50",
        range: "21-50",
        price: "589 zł"
    },
    {
        count: "100",
        range: "51-100",
        price: "899 zł"
    }
];

export const SubscriptionOptions = () => {
    const onClick = () => (window.location.href = appLink.agencyRegister());

    return (
        <>
            <section css={[wrapper, gradient]} id="subscription-options">
                <div css={[looseTextWrapper, white]}>
                    <p css={introPara}>
                        U nas płacisz tylko za efekt albo wybierasz abonament – w obu przypadkach zyskujesz <strong>więcej ofert</strong> niż u konkurencji!
                        <br />
                        Oto jest alternatywa.
                    </p>

                    <h3 css={cplCardHeadline}>
                        Oto rozliczenie <span>efektywnościowe</span>!
                    </h3>
                </div>

                <div css={subscriptionOptionsWrapper}>
                    <div css={optionContainer} onClick={onClick}>
                        <div css={subscriptionCardSection}>
                            <h3 css={cplCardHeadline}>Bezpłatnie opublikuj dowolną liczbę ofert</h3>
                            <h4 css={subscriptionCardSubHeadline}>
                                Płać jedynie za zgłoszenia od klientów – <span css={emphasize}>50 zł</span> / zgłoszenie
                            </h4>
                            <p css={cardParagraph}>minimalna ilość - 8 zgłoszeń</p>
                        </div>

                        <Button variant="success" href={appLink.agencyRegister()} css={[greenBg, cplButton]}>
                            Opublikuj!
                        </Button>
                    </div>
                </div>

                <div css={[looseTextWrapper, white]}>
                    <p css={introPara}>lub wybierz abonament!</p>
                </div>
            </section>
            <section css={wrapper}>
                <div>
                    <h3 css={subscriptionCardHeadline}>Oto nasz abonament</h3>

                    <div css={subscriptionOptionsWrapper}>
                        {options.map((option, idx) => (
                            <Card key={idx} {...option} onClick={onClick} />
                        ))}
                    </div>
                </div>
                <div css={looseTextWrapper}>
                    <p css={textForNewCustomers}>
                        Oferta specjalna dostępna wyłącznie dla agencji, które nie współpracowały z GetHome od co najmniej 3 miesięcy.
                    </p>
                </div>
            </section>
        </>
    );
};

const Card = (props: {count: string; range: string; price: string; onClick: () => void}) => {
    return (
        <div css={[optionContainer, optionContainerLighterHover]} onClick={props.onClick}>
            <div css={cardSection}>
                <h3 css={cardHeadline}>
                    Pakiet <span css={emphasize}>{props.count}</span>
                </h3>
                <div css={cardParagraph}>
                    <span css={emphasize}>{props.range}</span> ofert
                </div>
            </div>

            <div css={cardPriceSection}>
                <h4 css={cardSubHeader}>
                    <span css={emphasize}>0 zł netto</span>
                </h4>
                <p css={cardParagraph}>do końca czerwca 2025</p>
            </div>

            <div css={cardPriceSection}>
                <p css={cardParagraph}>{props.price} netto/od 1 lipca 2025</p>
            </div>

            <Button variant="success" href={appLink.agencyRegister()} css={greenBg}>
                Skorzystaj!
            </Button>
        </div>
    );
};

const wrapper = (theme: Theme) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 2rem;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        padding: 8rem 0 8rem;
    }
`;

const gradient = css`
    background-image: linear-gradient(to left, #543db7, #6647b9, #7552bb, #835dbe, #9069c0);
`;

const introPara = (theme: Theme) => css`
    text-align: center;
    color: #fff;
    line-height: 1.6;
    font-size: 1.8rem;
    margin: 0 0 2rem;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 2.5rem;
        margin: 0;
    }
`;

const looseTextWrapper = (theme: Theme) => css`
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        width: ${FOR_AGENT_SECTION_WIDTH};
    }
`;

const subscriptionOptionsWrapper = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    margin-bottom: 3rem;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        flex-direction: row;
        width: ${FOR_AGENT_SECTION_WIDTH};
        margin-bottom: 4rem;
    }
`;

const optionContainer = (theme: Theme) => css`
    display: flex;
    flex: 0 1 100%;
    font-size: 3rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border: 1px solid #e7e9ed;
    border-radius: 1rem;
    background-color: #ffffff;
    padding: 5rem 2rem;
    cursor: pointer;

    transition:
        box-shadow 0.2s ease-in-out,
        transform 0.2s ease-out;
    box-shadow: 0 1.6rem 3rem 0 rgba(0, 0, 0, 0.1);
    transform: translateY(0);

    &:hover {
        transform: translateY(-1px);
        box-shadow: 0 2.2rem 3.2rem 0 rgba(0, 0, 0, 0.4);
    }
`;

const optionContainerLighterHover = css`
    &:hover {
        transform: translateY(-1px);
        box-shadow: 0 2.2rem 3.2rem 0 rgba(0, 0, 0, 0.2);
    }
`;

const cplCardHeadline = (theme: Theme) => css`
    margin: 0 0 2rem;
    font-size: 2.6rem;
    text-align: center;

    span {
        font-size: 1.1em;
        font-weight: bold;
    }

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        margin: 2rem 0 4rem;
        font-size: 3.5rem;
    }
`;

const subscriptionCardHeadline = (theme: Theme) => css`
    margin: 0 0 2rem;
    font-size: 2.6rem;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        margin: 0 0 5rem;
        font-size: 3.5rem;
    }
`;

const subscriptionCardSection = css`
    margin-bottom: 3rem;
    text-align: center;
`;

const subscriptionCardSubHeadline = (theme: Theme) => css`
    margin: 0 0 1.5rem;
    font-weight: normal;
    font-size: 2rem;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 2.4rem;
        margin-bottom: 0.6rem;
    }
`;

const cardHeadline = css`
    margin: 0;
    text-align: center;
`;

const cardSection = css`
    margin-bottom: 2rem;
`;

const emphasize = css`
    color: ${getThemeVariable("colors-brand_primary")};
    font-size: 1.1em;
    font-weight: bold;
`;

const white = css`
    color: #fff;
`;

const cardParagraph = css`
    margin: 0 0 1rem;
    font-size: 1.6rem;
    text-align: center;
`;

const cardSubHeader = css`
    margin: 0;
    font-weight: normal;
    text-align: center;
`;

const cardPriceSection = css`
    margin-bottom: 3rem;
`;

const textForNewCustomers = (theme: Theme) => css`
    text-align: center;
    line-height: 1.6;
    font-size: 1.4rem;
    margin: 0 auto;
    max-width: 600px;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 2rem;
    }
`;

const greenBg = (theme: Theme) => css`
    background-color: ${theme.colors.green_darker};

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        background-color: ${darken(0.1, theme.colors.green_darker)};
        border-color: ${darken(0.1, theme.colors.green_darker)};
    }
`;

const cplButton = (theme: Theme) => css`
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.625;
    height: unset;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        font-size: 2.4rem;
        padding: 1.5rem 6rem;
        margin: 2rem 0;
    }
`;
