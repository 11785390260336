import * as React from "react";
import {useRef, useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {appLink} from "@web2/gh_routes";
import {CloseButtonIcon} from "@web2/icons";
import {useClickOutside} from "@web2/react_utils";

export const HeroSectionContactElement = () => {
    const [isContactOpen, setContactOpen] = useState(false);
    const contactsContentRef = useRef<HTMLElement | null>(null);
    const contactsContentRefHandle = (elem: HTMLElement | null) => {
        contactsContentRef.current = elem;
    };

    useClickOutside(contactsContentRef, () => {
        setContactOpen(false);
    });

    const onBtnClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setContactOpen(false);
    };

    return (
        <ContactBtn onClick={() => setContactOpen(true)} isActive={isContactOpen} ref={contactsContentRefHandle}>
            <div css={labelHolder}>
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="38" viewBox="0 0 31 38">
                    <path
                        d="M28.52,0H5.29A2.47,2.47,0,0,0,2.82,2.46V6.08H1a1,1,0,1,0,0,2H2.82v4H1a1,1,0,1,0,0,2H2.82v4H1a1,1,0,1,0,0,2H2.82v4H1a1,1,0,1,0,0,2H2.82v4H1a1,1,0,1,0,0,2H2.82v3.31A2.47,2.47,0,0,0,5.29,38H28.52A2.47,2.47,0,0,0,31,35.54V2.46A2.47,2.47,0,0,0,28.52,0Zm.42,35.54a.42.42,0,0,1-.42.42H5.29a.42.42,0,0,1-.42-.42V32.23H6.66a1,1,0,1,0,0-2H4.87v-4H6.66a1,1,0,1,0,0-2H4.87v-4H6.66a1,1,0,1,0,0-2H4.87v-4H6.66a1,1,0,1,0,0-2H4.87v-4H6.66a1,1,0,1,0,0-2H4.87V2.46A.42.42,0,0,1,5.29,2H28.52a.42.42,0,0,1,.42.42Zm-8-15.76a5.58,5.58,0,0,0,2.39-4.57,5.67,5.67,0,0,0-11.33,0,5.58,5.58,0,0,0,2.39,4.57,8.13,8.13,0,0,0-4,7.13,1,1,0,0,0,2,0c0-3.29,2.33-6,5.19-6s5.18,2.67,5.18,6a1,1,0,0,0,2.06,0A8.14,8.14,0,0,0,20.89,19.78Zm-3.27-1a3.58,3.58,0,1,1,3.61-3.57A3.59,3.59,0,0,1,17.62,18.78Z"
                        fill="#9069c0"
                    />
                </svg>
                Kontakt
                {isContactOpen && (
                    <div onClick={(e) => onBtnClose(e)}>
                        <ContactBtnClose width={10} height={10} />
                    </div>
                )}
            </div>

            {isContactOpen && (
                <div css={contacts}>
                    <div css={contactHolder}>
                        <div css={contactData}>
                            <a href={`mailto:biuro@gethome.pl}`}>biuro@gethome.pl</a>
                            <a href={`tel:+48733133531`}>+48 733 133 531</a>
                        </div>
                    </div>
                    <span css={paragraphTAndC}>
                        Administratorem danych jest Property Group Sp. z o.o.{" "}
                        <a href={appLink.termsRodo()} target="_blank" rel="noopener nofollow">
                            więcej o ochronie danych
                        </a>
                        .
                    </span>
                </div>
            )}
        </ContactBtn>
    );
};

const activeAnimation = css`
    animation: scale-up-tl 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    @keyframes scale-up-tl {
        0% {
            transform: scale(0.95);
            transform-origin: 0 0;
        }
        100% {
            transform: scale(1);
            transform-origin: 0 0;
        }
    }
`;

interface IContactStylesProps {
    isActive: boolean;
}

const ContactBtn = styled("div")<IContactStylesProps>`
    position: fixed;
    z-index: 1;
    top: 70px;
    left: 20px;
    padding: 0.7rem 2.5rem 0.7rem 1.5rem;
    color: ${(props) => props.theme?.colors.brand_primary};
    font-size: 1.8rem;
    font-weight: 500;
    cursor: ${(props) => (props.isActive ? "default" : "pointer")};
    background: ${(props) => (props.isActive ? "#fff" : "#efe8f6")};
    box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 2.5rem;
    border: 2px solid ${(props) => props.theme?.colors.brand_primary};
    display: none;

    @media (min-width: ${(props) => props.theme?.breakpoints.screen_sm}) {
        display: block;
    }

    ${(props) => props.isActive && activeAnimation}
`;

const contacts = (theme: Theme) => css`
    margin-top: 1.5rem;
    padding: 1.5rem 0 1.5rem 0.5rem;
    border-top: 1px solid ${theme.colors.gray_very_bright};
`;

const labelHolder = css`
    display: flex;
    align-items: center;

    svg {
        margin-right: 1rem;
    }
`;

const contactHolder = css`
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

const contactData = (theme: Theme) => css`
    display: flex;
    flex-direction: column;

    p,
    a {
        line-height: 1.4;
    }

    p {
        font-weight: 600;
        color: ${theme.colors.brand_primary};
        font-size: 1.4rem;
        margin: 0;
    }

    a {
        color: ${theme.colors.text_color};
        font-size: 1.2rem;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: ${theme.colors.text_color};
        }
    }
`;

const contactImg = css`
    height: 55px;
    width: 55px;
    margin-right: 1rem;
    border-radius: 50%;
`;

const ContactBtnClose = styled(CloseButtonIcon)`
    position: absolute;
    right: 15px;
    cursor: pointer;
    margin: 0;
    top: 22px;
    fill: ${(props) => props.theme.colors.brand_primary};
`;
const paragraphTAndC = (theme: Theme) => css`
    font-size: 1rem;
    margin: 2.5rem 0 0;
    max-width: 23rem;
    display: block;

    color: ${theme.colors.text_color};

    a {
        color: ${theme.colors.text_color};
    }
`;
