import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {appPath} from "@web2/gh_routes";
import {ArrowNextIcon} from "@web2/icons";

import {StyledAgencyButton} from "../../app/atoms/Buttons";
import {FlexColumn} from "../../app/atoms/Flex";
import {GridStatic} from "../../app/atoms/Layout";
import {handleRegisterAgencyBtn} from "../../tracking/google_tag_manager/registerAgencyBtnEvents";
import {sectionHeaderBase} from "./RegisterChecklist";

import cap from "../../styles/assets/images/partnerLogo/capital.png";
import hom from "../../styles/assets/images/partnerLogo/homfi_logo.png";
import propGold from "../../styles/assets/images/partnerLogo/property-gold.png";
import sad from "../../styles/assets/images/partnerLogo/sadurscy.png";
import sdp from "../../styles/assets/images/partnerLogo/sdp.png";

export const PartnerCollaborationSection = () => (
    <section css={partnerCollaborationWrapper}>
        <GridStatic width="1230px">
            <h2 css={sectionHeaderBase}>Współpracują z&nbsp;nami</h2>

            <LogoContainer justifyContent="center" alignItems="center">
                <PartnerLogo src={hom} height={80} width={223} alt="Homfi logo" loading="lazy" />
                <PartnerLogo src={propGold} height={150} width={164} alt="Property Gold logo" loading="lazy" />
                <PartnerLogo src={sad} height={127} width={266} alt="Bracia Sadurscy logo" loading="lazy" />
            </LogoContainer>

            <LogoContainer justifyContent="center" alignItems="center">
                <PartnerLogo src={sdp} height={116} width={187} alt="SDP Nieruchomości logo" loading="lazy" />
                <PartnerLogo src={cap} height={137} width={187} alt="The Capital Real Estate logo" loading="lazy" />
            </LogoContainer>

            <StyledAgencyButton href={appPath.agencyRegister} onClick={handleRegisterAgencyBtn} variant="success" size="lg">
                Dołącz do najlepszych!
                <ArrowNextIcon mainColor="#fff" />
            </StyledAgencyButton>
        </GridStatic>
    </section>
);

const partnerCollaborationWrapper = (theme: Theme) => css`
    padding: 2rem 0.5rem;

    @media screen and (min-width: ${theme.breakpoints_correct.screen_sm}) {
        padding: 3.5rem 0.5rem 5.5rem;
    }
`;

const LogoContainer = styled(FlexColumn)`
    margin-bottom: 5rem;
    gap: 2rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        flex-direction: row;
        gap: 10rem;
    }
`;

interface IImageStyles {
    height: number;
    width: number;
}

const PartnerLogo = styled.img<IImageStyles>`
    height: ${(props) => `${props.height}px`};
    width: ${(props) => `${props.width}px`};
    border-radius: 5px;

    &:not(:last-of-type) {
        margin-bottom: 3rem;
    }

    @media (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        margin-bottom: 0 !important;
    }
`;
