import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {appLink, appPath} from "@web2/gh_routes";
import {ArrowNextIcon, MailIcon, PhoneFillIcon} from "@web2/icons";

import {StyledAgencyButton} from "../../app/atoms/Buttons";
import {GridStatic} from "../../app/atoms/Layout";
import {Paragraph} from "../../app/atoms/Paragraph";
import {mr_md} from "../../styles/helpers";
import {getThemeVariable} from "../../styles/linaria_variable_factory";
import {handleRegisterAgencyBtn} from "../../tracking/google_tag_manager/registerAgencyBtnEvents";
import {FOR_AGENT_SECTION_WIDTH} from "../constants";
import {sectionHeaderBase} from "./RegisterChecklist";

export const ContactSection = () => (
    <>
        {" "}
        <section css={contactWrapper} id="contact">
            <GridStatic width="1230px">
                <h2 css={sectionHeader}>Skorzystaj z naszej oferty i nie płać aż do czerwca!</h2>

                <ParagraphSection>
                    Jesteśmy dostępni, aby odpowiedzieć na Twoje pytania i pomóc w rejestracji. <br />
                </ParagraphSection>

                <StyledAgencyButton href={appPath.agencyRegister} onClick={handleRegisterAgencyBtn} variant="success" size="lg" css={publishButton}>
                    Opublikuj za 0 zł
                    <ArrowNextIcon mainColor="#fff" />
                </StyledAgencyButton>

                <h2 css={sectionHeader}>Masz pytania?</h2>

                <ParagraphSection>Skontaktuj się!</ParagraphSection>

                <div css={contactLinksWrapper}>
                    <ContactLink href="mailto:biuro@gethome.pl">
                        <span css={[contactIcon, mr_md]}>
                            <MailIcon fillColor={getThemeVariable("colors-brand_primary")} size="2" />
                        </span>
                        biuro@gethome.pl
                    </ContactLink>

                    <ContactLink href="tel:+48733133531">
                        <span css={contactIcon}>
                            <PhoneFillIcon fillColor={getThemeVariable("colors-brand_primary")} size="1.8" />
                        </span>
                        +48 733 133 531
                    </ContactLink>
                </div>

                <p css={paragraphTAndC}>
                    Administratorem danych jest Property Group Sp. z o.o.{" "}
                    <a href={appLink.termsRodo()} target="_blank" rel="noopener nofollow">
                        więcej o ochronie danych
                    </a>
                    .
                </p>
            </GridStatic>
        </section>
        <section css={bottomBar} />
    </>
);

const contactWrapper = (theme: Theme) => css`
    position: relative;
    display: flex;
    justify-content: flex-start;
    height: auto;
    padding: 2rem;
    background: linear-gradient(180deg, rgba(240, 240, 240, 1) 0%, rgba(255, 255, 255, 1) 7%, rgba(255, 255, 255, 1) 100%);

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        padding: 5rem 2rem 0.5rem 2rem;
    }
`;

const sectionHeader = (theme: Theme) => css`
    ${sectionHeaderBase(theme)};
    font-size: 2.6rem;
    line-height: 1.5;

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        margin-bottom: 1.5rem;
        font-size: 3.2rem;
        line-height: 4.4rem;
    }
`;

const sectionSubHeader = (theme: Theme) => css`
    font-size: 2.6rem;
    text-align: center;
    margin-top: 7rem;
`;

const ParagraphSection = styled(Paragraph)`
    font-size: 2rem;
    line-height: 3rem;
    max-width: ${FOR_AGENT_SECTION_WIDTH};
    text-align: center;
    margin: 0 auto 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        margin: 0 auto 5rem;
        flex-direction: row;
        justify-content: center;
        gap: 5rem;
    }
`;

const contactLinksWrapper = (theme: Theme) => css`
    font-size: 2rem;
    margin: 0 auto 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 250px;

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        max-width: ${FOR_AGENT_SECTION_WIDTH};
        margin: 0 auto 5rem;
        flex-direction: row;
        gap: 5rem;
    }
`;

const paragraphTAndC = (theme: Theme) => css`
    font-size: ${theme.fonts.font_size_base};
    max-width: 905px;
    text-align: center;
    margin: 3rem auto 1rem;
    color: ${theme.colors.brand_primary};
    opacity: 0.5;

    a {
        color: ${theme.colors.brand_primary};
        text-decoration: underline;
    }
`;

const ContactLink = styled.a`
    color: ${getThemeVariable("colors-brand_primary")} !important;
    text-decoration: none !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
`;

const contactIcon = (theme: Theme) => css`
    margin-right: 1rem;
    display: none;

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        display: block;
    }
`;

const bottomBar = css`
    position: relative;
    //background-color: #ddd;
    height: 30px;
    background: linear-gradient(0deg, rgba(240, 240, 240, 1) 0%, rgba(255, 255, 255, 1) 100%);
`;

const publishButton = css`
    margin: 0 auto 6rem;
`;
