import {pluralize} from "@web2/string_utils";

import {appendQueryString} from "../../app/utils/append_query_string";

export type IOfferType = "apartment" | "house" | "property" | "lot";

export type UOfferTypes = "mieszkania" | "domy" | "dzialki" | "nieruchomosci";
export type UDealTypesPL = "do-wynajecia" | "na-sprzedaz";

export enum OfferDealType {
    SELL = "sell",
    RENT = "rent"
}

export enum DealTypeSlug {
    SELL = "na-sprzedaz",
    RENT = "do-wynajecia"
}

export enum OfferMarketType {
    AFTERMARKET = "aftermarket",
    PRIMARY_MARKET = "primary_market"
}

export enum PropertyType {
    APARTMENT = "apartment",
    HOUSE = "house",
    LOT = "lot"
}

export enum OfferType {
    house = "domy",
    apartment = "mieszkania",
    lot = "dzialki",
    property = "nieruchomosci"
}

export enum OfferTypePL {
    house = "domy",
    apartment = "mieszkania",
    lot = "działki",
    property = "nieruchomości"
}

export enum OfferTypeSinglePL {
    house = "dom",
    apartment = "mieszkanie",
    lot = "działka",
    property = "nieruchomość"
}

export enum OfferTypeDeclensionWhat {
    house = "domu",
    apartment = "mieszkania",
    lot = "działki",
    property = "nieruchomości"
}

export enum OfferTypeEN {
    house = "house",
    apartment = "apartment",
    lot = "lot",
    property = "property"
}

export enum FullOfferApplicationModalHeader {
    ASK_DEVELOPER_PRICE = "Zapytaj o cenę dewelopera",
    ASK_DEVELOPER_OFFER = "Zapytaj o ofertę dewelopera",
    ASK_AGENT_OFFER = "Zapytaj o ofertę agenta"
}

export function dealTypePL(dealType?: OfferDealType) {
    switch (dealType) {
        case OfferDealType.SELL:
            return "na sprzedaż";
        case OfferDealType.RENT:
            return "do wynajęcia";
        default:
            return "na sprzedaż";
    }
}

export function dealTypePLWithoutDiactricMarks(dealType?: OfferDealType) {
    switch (dealType) {
        case OfferDealType.SELL:
            return "na sprzedaz";
        case OfferDealType.RENT:
            return "do wynajecia";
        default:
            return "na sprzedaz";
    }
}

export function dealTypeVerbPL(dealType?: OfferDealType) {
    switch (dealType) {
        case OfferDealType.RENT:
            return "wynajmij";
        case OfferDealType.SELL:
            return "kup";
        default:
            return "kup lub wynajmij";
    }
}

export enum AgencySalesStatus {
    LOW = 1,
    STANDARD = 2,
    PREMIUM = 3
}

export const SAVED_USER_INFO_LS_NAME = "contact-form-saved-info";

export const housePluralize = pluralize(["domu", "domów", "domów"]);

export const flatPluralize = pluralize(["mieszkania", "mieszkań", "mieszkań"]);

export type IOfferListQueryType = "aftermarket__apartment" | "primary_market__apartment" | "aftermarket__house" | "primary_market__house" | "lot";
export const translateQueryOfferTypesIntoOfferType = (queryTypes: IOfferListQueryType[]): IOfferType => {
    const offerTypes = queryTypes.map(queryOfferTypeToOfferType);
    let differentTypesCount = 0;
    const hasApartment = offerTypes.find((t) => t === "apartment");
    hasApartment && ++differentTypesCount;
    const hasHouse = offerTypes.find((t) => t === "house");
    hasHouse && ++differentTypesCount;
    const hasLot = offerTypes.find((t) => t === "lot");
    hasLot && ++differentTypesCount;
    if (differentTypesCount === 1) {
        if (hasApartment) {
            return "apartment";
        }
        if (hasHouse) {
            return "house";
        }
        if (hasLot) {
            return "lot";
        }
    }
    return "property";
};
const queryOfferTypeToOfferType = (queryType: IOfferListQueryType): IOfferType => {
    const apartment: IOfferType = "apartment";
    if (queryType.indexOf(apartment) !== -1) {
        return "apartment";
    }
    const house: IOfferType = "house";
    if (queryType.indexOf(house) !== -1) {
        return "house";
    }
    const lot: IOfferType = "lot";
    if (queryType.indexOf(lot) !== -1) {
        return "lot";
    }
    return "property";
};

export function getPropertyTypeName(propertyType: PropertyType) {
    switch (propertyType) {
        case PropertyType.APARTMENT:
            return "mieszkanie";
        case PropertyType.HOUSE:
            return "dom";
        case PropertyType.LOT:
            return "działka";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getPropertyTypeNameWhat(propertyType: PropertyType) {
    switch (propertyType) {
        case PropertyType.APARTMENT:
            return "mieszkania";
        case PropertyType.HOUSE:
            return "domu";
        case PropertyType.LOT:
            return "działki";
        default:
            throw new Error("Unknown offer type");
    }
}

const HOUSING_PLATFORM_URL = "https://propertygroup.user.com/landing/mw1tw1/";
export const getHousingPlatformUrl = (props: {source: string}) =>
    appendQueryString(HOUSING_PLATFORM_URL, {
        utm_source: props.source,
        utm_medium: "baner",
        utm_campaign: "akcjarekrutacja"
    });
